<template>
  <CCard class="h-100 m-0">
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Order list" bold />
      <TSpinner :loading="loading" />
      <div class="ml-auto" v-if="showBtn">
        <TButton
          content="Add order"
          variant="outline"
          size=""
          icon="cil-plus"
          @click="showModalAdd = true"
          v-if="isContractOpen"
        />
      </div>
    </CCardHeader>
    <CCardBody v-if="list" class="w-100 mx-auto" style="max-width: 800x">
      <div>
        <TTableAdvance
          :items="list"
          :fields="fields"
          store="order.orders"
          @click-clear-filter="clearFilter"
          resource=""
          reloadable
          @click-reload="fetch"
        >
          <template #append-actions="{ item }">
            <div class="mx-auto">
              <TButtonRemove
                @click="removeOrder(item.id)"
                class="mx-auto"
                :options="{ disabled: !isContractOpen }"
              />
              <!-- <TButton
                icon="cis-call-swap-calls"
                size="sm"
                variant="ghost"
                class="mx-auto"
                tooltip="Change contract"
                @click="changeContract(item.id)"
                :options="{ disabled: !isContractOpen }"
              /> -->
            </div>
          </template>
          <template #trackings="{ item }">
            <td>
              <SMessageTracking
                v-if="item.trackings.length > 0"
                :value="item.trackings[0].code"
                :checked="item.trackings[0].received"
              />
              <TMessageNotFound v-else />
            </td>
          </template>
          <template #status="{ item }">
            <td>
              <SMessageOrderStatus :id="item.status_id" />
            </td>
          </template>
          <template #id="{ item }">
            <td>
              <TLink
                v-if="item.id"
                :content="item.id"
                :to="lodash.getReferenceLink(
                 'order',
                  item.id
                )"
              />
              <SMessageOrderType :id="item.type_id" />
            </td>
          </template>
          <template #shipment_method_id="{ item }">
            <td>
              <SMessageShipmentMethod :id="item.shipment_method_id" />
            </td>
          </template>
          <template #purchase_cost="{ item }">
            <td>
              <TMessageMoney
                :amount="item.purchase_cost"
                :currency="item.purchase_cost_currency_id"
              />
            </td>
          </template>
          <template #purchase_cost_paid="{ item }">
            <td>
              <TMessageMoney
                :amount="item.purchase_cost_paid"
                :currency="item.purchase_cost_currency_id"
              />
            </td>
          </template>
          <template #purchase_cost_outstanding="{ item }">
            <td>
              <TMessageMoney
                :amount="item.purchase_cost_outstanding"
                :currency="item.purchase_cost_currency_id"
              />
            </td>
          </template>
          <template #purchase_cost_debited="{ item }">
            <td>
              <TMessageMoney
                :amount="item.purchase_cost_debited"
                :currency="item.purchase_cost_currency_id"
              />
            </td>
          </template>
          <template #purchase_cost_unsolved="{ item }">
            <td>
              <TMessageMoney
                :amount="item.purchase_cost_unsolved"
                :currency="item.purchase_cost_currency_id"
              />
            </td>
          </template>
          <template #service_fee="{ item }">
            <td>
              <TMessageMoney
                :amount="item.service_fee"
                :currency="item.service_fee_currency_id"
              />
            </td>
          </template>
          <template #service_fee_paid="{ item }">
            <td>
              <TMessageMoney
                :amount="item.service_fee_paid"
                :currency="item.service_fee_currency_id"
              />
            </td>
          </template>
          <template #service_fee_outstanding="{ item }">
            <td>
              <TMessageMoney
                :amount="item.service_fee_outstanding"
                :currency="item.service_fee_currency_id"
              />
            </td>
          </template>
          <template #service_fee_debited="{ item }">
            <td>
              <TMessageMoney
                :amount="item.service_fee_debited"
                :currency="item.service_fee_currency_id"
              />
            </td>
          </template>
          <template #service_fee_unsolved="{ item }">
            <td>
              <TMessageMoney
                :amount="item.service_fee_unsolved"
                :currency="item.service_fee_currency_id"
              />
            </td>
          </template>
          <template #compensation="{ item }">
            <td>
              <TMessageMoney
                :amount="item.compensation"
                :currency="item.service_fee_currency_id"
              />
            </td>
          </template>
          <template #compensated="{ item }">
            <td>
              <TMessageMoney
                :amount="item.compensated"
                :currency="item.service_fee_currency_id"
              />
            </td>
          </template>
          <template #compensation_unsolved="{ item }">
            <td>
              <TMessageMoney
                :amount="item.compensation_unsolved"
                :currency="item.service_fee_currency_id"
              />
            </td>
          </template>
          <template #id-filter>
            <TInputText
              :value.sync="filter.id"
              @update:value="filterChange"
              placeholder="Order Id"
            />
          </template>
          <template #customer_id-filter>
            <SSelectCustomer
              :value.sync="filter.customer_id"
              @change="filterChange"
              noCustomLabel
              prependAll
            />
          </template>
          <template #trackings-filter>
            <TInputText
              :value.sync="filter['by_tracking_code']"
              @update:value="filterChange"
              placeholder="Tracking Id"
            />
          </template>
          <template #shipment_method_id-filter>
            <SSelectShipmentMethod
              :value.sync="filter.shipment_method_id"
              @change="filterChange"
            />
          </template>
          <template #status-filter>
            <SSelectOrderStatus
              store="order.statuses"
              :value.sync="filter.status_id"
              @change="filterChange"
            />
          </template>
        </TTableAdvance>
        <AddOrder
          :show.sync="showModalAdd"
          :customerId="customerId"
          :contract="contract"
          @updated="addOrder"
        />
        <!-- <ChangeContract
          :show.sync="showModalChangeContract"
          :orderId="orderIdSelected"
          :customerId="customerId"
          @updated="filterChange"
        /> -->
      </div>
    </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from "vuex";
import AddOrder from "./AddOrder.vue";
// import ChangeContract from "./ChangeContract.vue";
export default {
  components: {
    AddOrder,
    // ChangeContract,
  },
  props: {
    contractId: {
      type: String,
      required: true,
    },
    showBtn:{
      type:Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 45px; min-width: 45px" },
        {
          key: "id",
          label: "Order Id",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "trackings",
          label: "Tracking Id",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "shipment_method_id",
          label: "Shipment method",
          _classes: "",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "purchase_cost",
          label: "Purchase cost",
          _style: "width: 130px; min-width: 130px",
          _classes: "text-primary",
          sorter: true,
        },
        { key: "purchase_cost_paid", label: "Paid", sorter: true },
        {
          key: "purchase_cost_outstanding",
          label: "Outstanding",
          sorter: true,
        },
        { key: "purchase_cost_debited", label: "Debited", sorter: true },
        { key: "purchase_cost_unsolved", label: "Unsolved", sorter: true },
        {
          key: "service_fee",
          label: "Service fee",
          _classes: "text-primary text-nowrap",
          _style: "width: 130px; min-width: 130px",
          sorter: true,
        },
        { key: "service_fee_paid", label: "Paid", sorter: true },
        {
          key: "service_fee_outstanding",
          label: "Outstanding",
          sorter: true,
        },
        { key: "service_fee_debited", label: "Debited", sorter: true },
        { key: "service_fee_unsolved", label: "Unsolved", sorter: true },
        {
          key: "compensation",
          label: "Commodity compensation",
          sorter: true,
          _classes: "text-primary text-nowrap",
        },
        { key: "compensated", label: "Compensated", sorter: true },
        { key: "compensation_unsolved", label: "Uncompensated", sorter: true },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
      showModalAdd: false,
      showModalChangeContract: false,
      contractLoading: false,
      orderIdSelected: "",
    };
  },
  watch: {
    contractId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.filterChange();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "order.orders.list",
      contract: "order.contracts.detail",
      loading: "order.orders.loading",
    }),
    customerId() {
      return this.contract.customer_id;
    },
    isContractOpen() {
      return this.contract.status_id == "Open";
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch("order.orders.fetch");
    },
    filterChange() {
      const default_filter = {
        include: "trackings",
        "filter[contract_id]": this.contractId,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filterList,
        ...filterBetween,
        ...default_filter,
      });
      this.$store.dispatch("order.orders.apply-query", filterFields);
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["updated_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter = {};
      this.filter_between = {};
      this.filterChange();
    },
    removeOrder(id) {
      this.contractLoading = true;
      this.$store
        .dispatch("order.contracts.detail.detach-order", {
          order_ids: [id],
        })
        .then(() => {
          this.filterChange();
          this.$emit("updated");
        })
        .finally(() => {
          this.contractLoading = false;
        });
    },
    changeContract(id) {
      this.orderIdSelected = id;
      this.showModalChangeContract = true;
    },
    addOrder() {
      this.filterChange();
      this.$emit("updated");
    },
  },
};
</script>
