<template>
  <CCard class="h-100 m-0">
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Commodity information" bold />
      <TSpinner :loading="loading" />
      <!-- <div class="ml-auto">
        <TButton
          content="Add order"
          variant="outline"
          size=""
          icon="cil-plus"
          @click="showModalAdd = true"
          v-if="isContractOpen"
        />
      </div> -->
    </CCardHeader>
    <CCardBody v-if="data">
      <div>
        <TTableSimple
          :fields="fields"
          :items="data"
          resource=""
          size="sm"
          columnFilter
          :pagination="true"
          :options="{ striped: true, itemsPerPage: 30 }"
        >
          <template #_="{ item }">
            <td>
              <TButtonRemove
                tooltip="Remove the order from the contract"
                @click="removeOrder(item.order_id)"
                class="mx-auto"
                :options="{ disabled: !isContractOpen }"
              />
            </td>
          </template>
          <template #item="{ item }">
            <td>
              <SCardProductItems
                v-if="item.product"
                widthAuto
                style="width: 300px; font-size: 0.95rem"
                class="text-wrap text-break"
                :item="item.product"
                resource="/sale/goods/products"
              />
            </td>
          </template>
          <template #price="{ item }">
            <td>
              <TMessageMoney
                :amount="item.price"
                :currency="contract.purchase_cost_currency_id"
              />
            </td>
          </template>
          <template #discount_tax="{ item }">
            <td>
              <TMessageMoney
                :amount="item.discount_tax"
                :currency="contract.purchase_cost_currency_id"
              />
            </td>
          </template>
          <template #tax_percent="{ item }">
            <td>
              <TMessagePercent :value="item.tax_percent" />
            </td>
          </template>
          <template #id="{ item }">
            <td>
              <TLink
                v-if="item.order_id"
                :content="item.order_id"
                :to="lodash.getReferenceLink(
                  'order',
                  item.order_id
                )"
              />
            </td>
          </template>
          <template #quantity="{ item }">
            <td>
              <TMessageNumber :value="item.quantity" />
            </td>
          </template>
          <template #quantity_items="{ item }">
            <td>
              <TMessageNumber
                v-if="item.product"
                :value="item.product.quantity_items"
              />
            </td>
          </template>
          <template #boxes_quantity="{ item }">
            <td>
              <TMessageNumber
                v-if="item.product"
                :value="item.quantity / item.product.quantity_items"
                class="font-weight-bold"
              />
            </td>
          </template>
          <template #weight="{ item }">
            <td>
              <TMessageNumber
                v-if="item.product"
                :value="item.product.weight"
                suffix="kg"
              />
            </td>
          </template>
          <template #height="{ item }">
            <td>
              <TMessageNumber
                v-if="item.product"
                :value="item.product.height"
                suffix="cm"
              />
            </td>
          </template>
          <template #width="{ item }">
            <td>
              <TMessageNumber
                v-if="item.product"
                :value="item.product.width"
                suffix="cm"
              />
            </td>
          </template>
          <template #length="{ item }">
            <td>
              <TMessageNumber
                v-if="item.product"
                :value="item.product.length"
                suffix="cm"
              />
            </td>
          </template>
          <template #total_weight="{ item }">
            <td>
              <TMessageNumber
                v-if="item.product"
                :value="
                  (item.product.weight * item.quantity) /
                  item.product.quantity_items
                "
                suffix="kg"
              />
            </td>
          </template>

          <template #volume="{ item }">
            <td>
              <TMessageVolume
                v-if="item.product"
                :value="
                  (item.product.width *
                    item.product.height *
                    item.product.length *
                    item.quantity) /
                    item.product.quantity_items || 0
                "
              />
            </td>
          </template>
          <template #note="{ item }">
            <td>
              <TMessage :content="item.note" noTranslate />
            </td>
          </template>
          <template #sku="{ item }">
            <td>
              <div v-for="box in item.boxes" :key="box.id">
                <TMessageText :value="box.id" />
              </div>
              <TMessageNotFound v-if="!item.boxes.length" />
            </td>
          </template>
          <template #payment_due_date="{ item }">
            <td>
              <div v-for="dis in item.distributions" :key="dis.id">
                <TMessageDateTime
                  v-if="dis.purchase_item"
                  :content="dis.purchase_item.payment_due_date"
                  dateOnly
                />
              </div>
            </td>
          </template>
          <template #expected_delivery="{ item }">
            <td>
              <div v-for="dis in item.distributions" :key="dis.id">
                <TMessageDateTime
                  v-if="dis.purchase_item"
                  :content="dis.purchase_item.expected_delivery"
                  dateOnly
                />
              </div>
            </td>
          </template>
          <template #balance="{ item }">
            <td>
              <TMessageMoney
                :amount="item.balance"
                :currency="contract.purchase_cost_currency_id"
              />
            </td>
          </template>
          <template #result-volume>
            <th>
              <div class="d-flex text-primary">
                <TMessage content="Total:" />
                <i>
                  <TMessageVolume :value="getTotalVolume()" class="ml-1" />
                </i>
              </div>
            </th>
          </template>
          <template #result-total_weight>
            <th>
              <div class="d-flex text-primary">
                <TMessage content="Total:" />
                <i>
                  <TMessageNumber
                    :value="getTotalWeight()"
                    class="ml-1"
                    suffix="kg"
                  />
                </i>
              </div>
            </th>
          </template>
          <template #id-filter>
            <TInputText placeholder="Order Id" :value.sync="order_id" />
          </template>
          <template #item-filter>
            <TInputText placeholder="Product Id" :value.sync="product_id" />
          </template>
          <template #_-filter>
            <TButtonClear
              variant="outline"
              class="mb-1 ml-2"
              @click="clearFilter"
            />
          </template>
        </TTableSimple>
      </div>
    </CCardBody>
    <AddOrder
      :show.sync="showModalAdd"
      :customerId="contract.customer_id"
      :contract="contract"
      @updated="refreshContract"
    />
  </CCard>
</template>
<script>
import { mapGetters } from "vuex";
import AddOrder from "./AddOrder.vue";
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    AddOrder,
  },
  data() {
    return {
      fields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Total",
        },
        {
          key: "id",
          label: "Order Id",
          _style: "width: 120px; min-width: 120px",
        },
        {
          key: "item",
          label: "Product",
          _style: "width: 300px; min-width: 300px",
        },
        {
          key: "price",
          label: "Price",
          _classes: "",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "tax_percent",
          label: "Tax",
          _classes: "text-nowrap",
          _style: "width: 50px; min-width: 50px",
        },
        {
          key: "discount_tax",
          label: "Discount tax",
          _classes: "text-wrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "balance",
          label: "Total amount",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "quantity",
          label: "Quantity",
          _classes: "text-nowrap",
        },
        {
          key: "quantity_items",
          label: "Items in box",
          _classes: "text-wrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "boxes_quantity",
          label: "Number of boxes",
          _classes: "text-wrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "weight",
          label: "Weight",
          _style: "width: 50px; min-width: 50px",
        },
        {
          key: "height",
          label: "Height",
          _style: "width: 50px; min-width: 50px",
        },
        {
          key: "length",
          label: "Length",
          _style: "width: 50px; min-width: 50px",
        },
        {
          key: "width",
          label: "Width",
          _style: "width: 50px; min-width: 50px",
        },
        {
          key: "total_weight",
          label: "Total weight",
          _classes: "text-wrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "volume",
          label: "Volume",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "sku",
          label: "SKU Id",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "payment_due_date",
          label: "Payment due date",
          _classes: "text-wrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "expected_delivery",
          label: "Desired delivery",
          _classes: "text-wrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "note",
          label: "Note",
          _style: "width: 200px; min-width: 200px",
        },
      ],
      contractLoading: false,
      showModalAdd: false,
      product_id: "",
      order_id: "",
    };
  },
  computed: {
    ...mapGetters({
      contract: "order.contracts.detail",
      loading: "order.contracts.loading",
    }),
    isContractOpen() {
      return this.contract.status_id == "Open";
    },
    data() {
      return this.lodash.filter(
        this.items,
        this.lodash.pickBy({
          product_id: this.product_id,
          order_id: this.order_id,
        })
      );
    },
  },
  methods: {
    refreshContract() {
      this.$store.dispatch(`order.contracts.detail.refresh`);
    },
    getTotalVolume() {
      return this.data.reduce((accumulator, x) => {
        const totalV =
          (x?.product?.width *
            x?.product?.height *
            x?.product?.length *
            x.quantity) /
            x?.product?.quantity_items || 0;
        return (accumulator = accumulator + totalV);
      }, 0);
    },
    getTotalWeight() {
      return this.data.reduce((accumulator, x) => {
        const total =
          (x?.product?.weight * x.quantity) / x?.product?.quantity_items || 0;
        return (accumulator = accumulator + total);
      }, 0);
    },
    removeOrder(id) {
      this.contractLoading = true;
      this.$store
        .dispatch("order.contracts.detail.detach-order", {
          order_ids: [id],
        })
        .then(() => {
          this.refreshContract();
        })
        .finally(() => {
          this.contractLoading = false;
        });
    },
    clearFilter() {
      this.order_id = "";
      this.product_id = "";
    },
  },
};
</script>
