<template>
  <TModal
    title="Add orders to the contract"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="lg"
  >
    <template #actions>
      <div class="d-flex w-100">
        <TMessage content="Selected: ">
          <template #suffix>{{ ordersSelected.length }}</template>
        </TMessage>
        <CLink
          class="ml-2 d-flex"
          @click="ordersSelected = []"
          v-if="ordersSelected.length"
        >
          (<TMessage content="Deselect all" bold />)
        </CLink>
        <TButton
          :options="{ disabled: lodash.isEmpty(ordersSelected) || loading }"
          variant="outline"
          content="Add to contract"
          icon="cil-check"
          class="ml-auto"
          @click="submitOrdersSelected"
        />
      </div>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="order.order_available_for_contract"
      @click-clear-filter="clearFilter"
      resource=""
      reloadable
      @click-reload="fetch"
    >
      <template #append-actions="{ item }">
        <div class="m-auto">
          <TInputCheckbox
            :checked="ordersSelected.includes(item.id)"
            @change="selectOrder(item.id)"
            class="ml-2"
          />
        </div>
      </template>
      <template #trackings="{ item }">
        <td>
          <TMessage
            v-if="item.trackings.length > 0"
            :content="item.trackings[0].code"
            noTranslate
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate />
        </td>
      </template>
      <template #shipment_method_id="{ item }">
        <td>
          <SMessageShipmentMethod :id="item.shipment_method_id" />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Order Id"
        />
      </template>
      <template #trackings-filter>
        <TInputText
          :value.sync="filter['by_tracking_code']"
          @update:value="filterChange"
          placeholder="Tracking Id"
        />
      </template>
      <template #shipment_method_id-filter>
        <SSelectShipmentMethod
          :value.sync="filter.shipment_method_id"
          @change="filterChange"
        />
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contract: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      fields: [
        { key: "_", _style: "width: 40px; max-width: 50px" },
        {
          key: "id",
          label: "Order Id",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "trackings",
          label: "Tracking Id",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "shipment_method_id",
          label: "Shipment method",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
      ],
      filter: {},
      filter_between: {},
      ordersSelected: [],
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        if (show) {
          this.filterChange();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "order.order_available_for_contract.list",
    }),
  },
  methods: {
    fetch() {
      this.$store.dispatch("order.order_available_for_contract.fetch");
    },
    submitOrdersSelected() {
      this.loading = true;
      const data = {
        order_ids: this.ordersSelected,
      };
      this.$store
        .dispatch("order.contracts.detail.attach-order", data)
        .then(() => {
          this.ordersSelected = [];
          this.$emit("update:show", false);
          this.$emit("updated");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectOrder(id) {
      const index = this.ordersSelected.findIndex((x) => x === id);
      if (index < 0) {
        this.ordersSelected.push(id);
      } else this.ordersSelected.splice(index, 1);
    },
    filterChange() {
      const default_filter = {
        "filter[customer_id]": this.contract.customer_id,
        "filter[sales_organization_id]": this.contract.sales_organization_id,
        "filter[transport_organization_id]":
          this.contract.transport_organization_id,
        "filter[purchase_cost_currency_id]":
          this.contract.purchase_cost_currency_id,
        "filter[service_fee_currency_id]":
          this.contract.service_fee_currency_id,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filterList,
        ...filterBetween,
        ...default_filter,
      });
      this.$store.dispatch(
        "order.order_available_for_contract.apply-query",
        filterFields
      );
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["updated_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter = {};
      this.filter_between = {};
      this.filterChange();
    },
  },
};
</script>
