<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center flex-wrap">
        <strong>{{ detail.id }}</strong>
        <SMessageContractStatus :status="detail.status_id" class="ml-1" />
        <TSpinner :loading="loading" />
        <div class="ml-auto" v-if="showBtn">
          <SButtonDebit
            v-if="detail.service_fee_unsolved || detail.purchase_cost_unsolved"
            @click="debit"
            class="ml-1"
            :options="{ disabled: loading }"
          />
          <TButton
            class="ml-1"
            size="sm"
            :content="contentBtnContract"
            :color="colorBtnContract"
            @click="changeStatusContract"
            :options="{ disabled: loading }"
          />
          <TButtonRefresh
            class="ml-1"
            @click="refresh"
            :options="{ disabled: loading }"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow class="mb-4">
          <CCol sm="12" md="6" lg="6" :xl="{ size: '4' }">
            <TTableAsForm
              title="Details"
              :data="detail"
              :fields="detailFields"
              class="mb-4"
              :splitLeft="7"
            >
              <template #id="{ value }">
                <TMessageText
                  :value="value"
                  :editable="editable"
                  @change="updateContractId"
                />
              </template>
              <template #customer="{ value }">
                <TLink
                  :content="detail.customer ? value.name : detail.customer_id"
                  :to="lodash.getReferenceLink('user', detail.customer_id)"
                  :messageOptions="{ bold: true }"
                />
              </template>
              <template #sales_organization_id="{ value }">
                <SMessageOrganization :id="value" />
              </template>
              <template #transport_organization_id="{ value }">
                <SMessageOrganization :id="value" />
              </template>
              <template #created_at="{ value }">
                <TMessageDateTime :content="value" dateOnly />
              </template>
              <template #updated_at="{ value }">
                <TMessageDateTime :content="value" dateOnly />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol sm="12" md="6" lg="6" :xl="{ size: '4' }">
            <TTableAsForm
              title="Dates"
              :data="detail"
              :fields="dateFields"
              class="mb-4"
              :splitLeft="7"
            >
              <template #start_date="{ value }">
                <TMessageDateTime
                  :content="value"
                  dateOnly
                  :editable="editable"
                  @change="updateContract('start_date', $event)"
                />
              </template>
              <template #end_date="{ value }">
                <TMessageDateTime
                  :content="value"
                  dateOnly
                  :editable="editable"
                  @change="updateContract('end_date', $event)"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol sm="12" md="6" lg="6" :xl="{ size: '4' }">
            <TTableAsForm
              title="Compensation"
              :data="detail"
              :fields="compensationFields"
              :splitLeft="7"
              class="mb-4"
            >
              <template #compensation="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #compensated="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #compensation_unsolved="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol sm="12" md="6" lg="6" :xl="{ size: '4' }">
            <TTableAsForm
              title="Goods money"
              :data="detail"
              :fields="goodsMoneyFields"
              :splitLeft="7"
              class="mb-4"
            >
              <template #purchase_cost="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.purchase_cost_currency_id"
                />
              </template>
              <template #purchase_cost_paid="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.purchase_cost_currency_id"
                />
              </template>
              <template #purchase_cost_unsolved="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.purchase_cost_currency_id"
                />
              </template>
              <template #purchase_cost_debited="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.purchase_cost_currency_id"
                />
              </template>
              <template #purchase_cost_outstanding="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.purchase_cost_currency_id"
                />
              </template>
              <template #additional_cost="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.purchase_cost_currency_id"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol sm="12" md="6" lg="6" :xl="{ size: '4' }">
            <TTableAsForm
              title="Service charge"
              :data="detail"
              :fields="serviceChargeFields"
              :splitLeft="7"
              class="mb-4"
            >
              <template #service_fee="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #service_fee_paid="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #service_fee_unsolved="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #service_fee_debited="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #service_fee_outstanding="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
            </TTableAsForm>
          </CCol>

          <CCol sm="12" md="6" lg="6" :xl="{ size: '4' }">
            <TTableAsForm
              :fields="freightChargeFields"
              :data="detail"
              :splitLeft="7"
              title="Freight charge"
              class="mb-3"
            >
              <template #freight_charge="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #freight_charge_debited="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #freight_charge_outstanding="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #freight_charge_paid="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #freight_charge_unsolved="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #discount="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol sm="12" md="6" lg="6" :xl="{ size: '4' }">
            <TTableAsForm
              :fields="otherCostFields"
              :data="detail"
              :splitLeft="7"
              title="Other fees"
              class="mb-3"
            >
              <template #buying_agent_fee="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #cod_cost="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #special_declaration="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
              <template #insurance_declaration="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="detail.service_fee_currency_id"
                />
              </template>
            </TTableAsForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  props: {
    showBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      detailFields: [
        { key: "id", label: "Contract name" },
        { key: "customer", label: "Customer" },
        { key: "sales_organization_id", label: "Purchasing company" },
        { key: "transport_organization_id", label: "Transportation company" },
        { key: "created_at", label: "Created at" },
        { key: "updated_at", label: "Updated at" },
      ],
      dateFields: [
        { key: "start_date", label: "Start date" },
        { key: "end_date", label: "End date" },
      ],
      goodsMoneyFields: [
        { key: "purchase_cost", label: "Total amount" },
        { key: "additional_cost", label: "Additional cost" },
        { key: "purchase_cost_paid", label: "Paid" },
        { key: "purchase_cost_outstanding", label: "Outstanding" },
        { key: "purchase_cost_debited", label: "Debited" },
        { key: "purchase_cost_unsolved", label: "Unsolved" },
      ],
      serviceChargeFields: [
        { key: "service_fee", label: "Total amount" },
        { key: "service_fee_paid", label: "Paid" },
        { key: "service_fee_outstanding", label: "Outstanding" },
        { key: "service_fee_debited", label: "Debited" },
        { key: "service_fee_unsolved", label: "Unsolved" },
      ],
      otherCostFields: [
        { key: "buying_agent_fee", label: "Purchase surcharge" },
        { key: "cod_cost", label: "Domestic delivery fee" },
        { key: "insurance_declaration", label: "Insurance declaration" },
        { key: "special_declaration", label: "Special declaration" },
      ],
      compensationFields: [
        { key: "compensation", label: "Commodity compensation" },
        { key: "compensated", label: "Compensated" },
        { key: "compensation_unsolved", label: "Uncompensated" },
      ],
      freightChargeFields: [
        { key: "freight_charge", label: "Total freight" },
        { key: "discount", label: "Freight charge discount" },
        { key: "freight_charge_paid", label: "Paid" },
        {
          key: "freight_charge_outstanding",
          label: "Outstanding",
        },
        { key: "freight_charge_debited", label: "Debited" },
        { key: "freight_charge_unsolved", label: "Unsolved" },
      ],
    };
  },
  computed: {
    detail() {
      return this.$store.getters[`order.contracts.detail`];
    },
    loading() {
      return this.$store.getters[`order.contracts.detail.loading`];
    },
    editable() {
      return !this.loading && this.isOpenContract;
    },
    id() {
      return this.$route.params.id;
    },
    isOpenContract() {
      return this.detail.status_id == "Open";
    },
    contentBtnContract() {
      return this.isOpenContract ? "Close the contract" : "Open the contract";
    },
    colorBtnContract() {
      return this.isOpenContract ? "danger" : "success";
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch(`order.contracts.detail.fetch`, this.id);
    },
    changeStatusContract() {
      const status = this.isOpenContract ? "Closed" : "Open";
      this.$store.dispatch("order.contracts.detail.update", {
        status_id: status,
      });
    },
    updateContract(field, value) {
      this.$store.dispatch("order.contracts.detail.update", {
        [field]: value,
      });
    },
    updateContractId(value) {
      this.$store
        .dispatch("order.contracts.detail.update", {
          id: value,
        })
        .then(() => {
          this.$router.push(`/sale/contracts/contracts/${value}`);
        });
    },
    debit() {
      this.$store.dispatch("order.contracts.detail.update", {
        action_debit: true,
      });
    },
  },
};
</script>
